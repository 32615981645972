import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";

const AddEventModal = ({ onClose, onSelectName, name, date, title, fetchEvents }) => {
  const [users, setUsers] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    fetch(`${API_BASE_URL}/users`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, []);

  const handleSubmit = async () => {
    try {
      await fetch(`${API_BASE_URL}/agenda/addevent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          createdby: selectedName,
          date: selectedDate,
          name: selectedTitle,
        }),
      });

      console.log("Event successfully added");
      onSelectName(selectedName, selectedDate, selectedTitle);
      onClose();
      await fetchEvents();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white text-black p-4 rounded-md max-w-xs w-full">
        <h2 className="text-lg font-semibold mb-4">Voeg evenement toe</h2>
        <div>
          <label className="block mb-2">Toegevoegd door:</label>
          <select
            className="w-full border p-2"
            value={selectedName}
            onChange={(e) => setSelectedName(e.target.value)}
          >
            <option value="">Selecteer een naam</option>
            {users.map((member) => (
              <option key={member.id} value={member.name}>
                {member.name}
              </option>
            ))}
          </select>
          <label className="block mb-2">Datum:</label>
          <input
            type="date"
            className="w-full border p-2"
            value={selectedDate}
            onChange={handleDateChange}
          />
          <label className="block mb-2">Titel:</label>
          <input
            type="text"
            className="w-full border p-2"
            value={selectedTitle}
            onChange={(e) => setSelectedTitle(e.target.value)}
            placeholder="Voorbeeld: bier drinken"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-yellow-500 text-white rounded-md mr-2"
            onClick={handleSubmit}
            disabled={!selectedName || !selectedDate || !selectedTitle}
          >
            Toevoegen
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={onClose}
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEventModal;
