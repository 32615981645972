import { FiGithub, FiMail, FiLinkedin } from "react-icons/fi";

const socialLinks = [
  {
    id: 1,
    icon: <FiMail />,
    url: "mailto:jmanders07@outlook.com",
  },
  {
    id: 2,
    icon: <FiGithub />,
    url: "https://github.com/JManders07",
  },
  {
    id: 3,
    icon: <FiLinkedin />,
    url: "https://www.linkedin.com/in/jelle-manders-3a7957193/",
  },
];

const Footer = () => {
  return (
    <div className="container mx-auto">
      <div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
        {/* Footer social links */}
        <div className="font-general-regular flex flex-col justify-center items-center mb-12 sm:mb-28">
          
          <ul className="flex gap-4 sm:gap-8">
            {socialLinks.map((link) => (
              <a
                href={link.url}
                target="__blank"
                key={link.id}
                className="text-indigo-500 cursor-pointer rounded-full bg-gray-700 hover:bg-gray-700 p-4 duration-300"
              >
                <i className="text-xl">{link.icon}</i>
              </a>
            ))}
          </ul>
          <p className="text-xl text-primary-dark text-indigo-500 mb-1 mt-1">Created by Jelle. © 2024</p>
          <p className="text-xl text-primary-dark text-indigo-500 ">Het Kantoor </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
