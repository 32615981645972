import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
const LogoutButton = () => {
    const { logout  } = useAuth0();
  return (
    <button
    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      className="text-yellow-500 border-yellow-500"
      variant="outline"
    >
      Logout
    </button>
  );
};
export default LogoutButton;
