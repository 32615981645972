import React, { useState, useEffect } from "react";

const AdjustCreditsForMultipleUsersModal = ({ onClose, onAdjust, users = [] }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, [users]);

  const handleAddUser = (userId) => {
    const selectedUser = users.find((user) => user.id === userId);
    if (selectedUser && !selectedUsers.some((user) => user.id === userId)) {
      setSelectedUsers([...selectedUsers, { ...selectedUser, amount: 6, latestAmount: selectedUser.latestAmount }]);
    }
  };

  const handleAmountChange = (userId, value) => {
    const amount = Number(value);
    if (!isNaN(amount)) {
      setSelectedUsers(
        selectedUsers.map((user) =>
          user.id === userId ? { ...user, amount } : user
        )
      );
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  const handleSubmit = () => {
    selectedUsers.forEach((user) => {
      let adjustment = -user.amount;
      onAdjust(user.id, adjustment, currentDate, user.latestAmount);
    });
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4">Tegoed aanpassen</h2>
        <div>
          <label className="block mb-2">Gebruiker toevoegen:</label>
          {users.length > 0 ? (
            <select
              className="w-full border p-2"
              onChange={(e) => handleAddUser(e.target.value)}
            >
              <option value="">Selecteer een gebruiker</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          ) : (
            <p>Geen gebruikers beschikbaar om te selecteren.</p>
          )}
        </div>

        <div className="mt-4">
          <label className="block mb-2">Datum:</label>
          <input
            type="date"
            className="w-full border p-2"
            value={currentDate}
            onChange={(e) => setCurrentDate(e.target.value)}
          />
        </div>

        <div className="mt-4">
          <h3 className="font-semibold mb-2">Geselecteerde gebruikers:</h3>
          {selectedUsers.length === 0 && <p>Geen gebruikers geselecteerd.</p>}
          {selectedUsers.map((user) => (
            <div key={user.id} className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <span>{user.name}</span>
                <select
                  className="w-full border p-2 ml-2"
                  value={user.amount}
                  onChange={(e) => handleAmountChange(user.id, e.target.value)}
                >
                  <option value={6}>Bier (€6)</option>
                  <option value={4}>Frisdrank (€4)</option>
                  <option value={10}>Mixdrank (€10)</option>
                </select>

                <button
                  className="ml-2 text-red-500"
                  onClick={() => handleRemoveUser(user.id)}
                >
                  &#x2716;
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-yellow-500 text-white rounded-md mr-2"
            onClick={handleSubmit}
            disabled={selectedUsers.length === 0}
          >
            Aanpassen
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={onClose}
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdjustCreditsForMultipleUsersModal;
