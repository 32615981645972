import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";
import AddEventModal from "./AddEventModal";
import beerimage from "../Img/beer_931949.png";

const Agenda = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const openAddEventModal = () => {
    setShowAddEventModal(true);
  };

  const closeAddEventModal = () => {
    setShowAddEventModal(false);
  };

  const handleSelectName = (name, date, title) => {
    setSelectedDate(date);
    setSelectedTitle(title);
    setSelectedUserName(name);
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(
          prevMonth.getFullYear(),
          prevMonth.getMonth() - 1,
          prevMonth.getDate()
        )
    );
  };

  const goToNextMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(
          prevMonth.getFullYear(),
          prevMonth.getMonth() + 1,
          prevMonth.getDate()
        )
    );
  };

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/agenda`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setEvents(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const renderDaysOfMonth = () => {
    const daysOfMonth = [];
    const currentDate = new Date(currentMonth);
    const currentYear = currentDate.getFullYear();
    const currentMonthIndex = currentDate.getMonth();
    const daysInMonth = new Date(currentYear, currentMonthIndex + 1, 0).getDate();
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonthToday = today.getMonth();
    const currentYearToday = today.getFullYear();

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDayDate = new Date(currentYear, currentMonthIndex, day);

      const dayEvents = events.filter(
        (event) =>
          new Date(event.date).toDateString() === currentDayDate.toDateString()
      );

      let textColor = "text-yellow-500"; // Default color

      if (
        currentDay === day &&
        currentMonthToday === currentMonthIndex &&
        currentYearToday === currentYear
      ) {
        textColor = "text-red-500"; // Set to red if it's today in the current month
      }

      daysOfMonth.push(
        <div key={day} className={`day bg-black ${textColor} p-4 rounded`}>
          <p className="font-semibold">{formatDate(currentDayDate)}</p>

          {dayEvents.map((event, index) => (
            <div key={index} className="event">
              {event.events.map((eventDetail, idx) => (
                <div key={idx}>
                  <p>{eventDetail.Name} (Door: {eventDetail.CreatedBy})</p>
                  <p></p>
                </div>
              ))}
            </div>
          ))}
          <hr className="my-2 border-yellow-500" />
        </div>
      );
    }
    return daysOfMonth;
  };

  const formatDate = (date) => {
    const monthNames = [
      "Januari", "Februari", "Maart", "April", "Mei", "Juni",
      "Juli", "Augustus", "September", "Oktober", "November", "December"
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const formatDateTop = (date) => {
    const monthNames = [
      "Januari", "Februari", "Maart", "April", "Mei", "Juni",
      "Juli", "Augustus", "September", "Oktober", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const shortenButtonText = (text, maxLength) => {
    return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
  };

  if (loading)
    return (
      <div className="mt-10 text-center font-semibold">
        <div className="text-yellow-500 bg-black inline-block p-4 rounded-lg">
          <div className="animate-bounce text-yellow-500 flex items-center justify-center">
            <a
              href="https://www.pornhub.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <img src={beerimage} alt="bouncing beer" className="h-32" />
            </a>
          </div>
          Laden... de server is aan het opstarten (15-20 seconden). Klik op het
          biertje om de tijd te doden.
        </div>
      </div>
    );

    return (
      <div className="container mx-auto p-8 bg-black text-yellow-500">
        <h1 className="text-3xl font-bold mb-4">Agenda </h1>
        <button
          className="bg-yellow-500 text-black px-2 py-1 rounded mb-2"
          onClick={openAddEventModal}
        >
          {shortenButtonText("Voeg evenement toe", 18)}
        </button>
        
        {showAddEventModal && (
          <AddEventModal
            onClose={closeAddEventModal}
            onSelectName={handleSelectName}
            name={selectedUserName}
            date={selectedDate}
            title={selectedTitle}
            fetchEvents={fetchEvents}
          />
        )}
        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={goToPreviousMonth}
            className="bg-yellow-500 text-black px-2 py-1 rounded w-20 h-8 sm:w-32 sm:h-12"
          >
            Vorige Maand
          </button>
          <p className="text-lg sm:text-xl md:text-2xl">
            {`${formatDateTop(currentMonth)}`}
          </p>
          <button
            onClick={goToNextMonth}
            className="bg-yellow-500 text-black px-2 py-1 rounded w-20 h-8 sm:w-32 sm:h-12"
          >
            Volgende Maand
          </button>
          
        </div>
        <hr className="my-4 border-yellow-500" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {renderDaysOfMonth()}
        </div>
      </div>
    );
};

export default Agenda;
