import React from "react";
import Agenda from "../Components/Agenda";

const AgendaPage = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor agenda
        </h1>
      
      </div>
      <div className="border shadow-sm rounded-lg bg-gray-900 overflow-x-auto"></div>
      <div>
        <Agenda />
      </div>
    </div>
  );
};

export default AgendaPage;
