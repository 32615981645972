import React, { useState, useEffect } from "react";

const AdjustCreditsModal = ({ onClose, onAdjust, userId, userName }) => {
  const [adjustmentType, setAdjustmentType] = useState("decrease");
  const [amount, setAmount] = useState(6);
  const [selectedOption, setSelectedOption] = useState("6");
  const [customAmount, setCustomAmount] = useState(0);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = () => {
    let adjustment;
    if (adjustmentType === "increase") {
      adjustment = amount;
    } else if (adjustmentType === "decrease") {
      adjustment = selectedOption === "custom" ? customAmount : amount;
      adjustment = -adjustment; 
    }

    onAdjust(userId, adjustment, currentDate); // Voer de aanpassing uit via de handleAdjustment
    onClose(); // Sluit de modal
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md max-w-xs w-full">
        <h2 className="text-lg font-semibold mb-4">Tegoed aanpassen van {userName}</h2>
        <div>
          <label className="block mb-2">Type aanpassing:</label>
          <select
            className="w-full border p-2"
            value={adjustmentType}
            onChange={(e) => setAdjustmentType(e.target.value)}
          >
            <option value="decrease">Tegoed aftrekken</option>
            <option value="increase">Tegoed toevoegen</option>
          </select>
        </div>
        <div className="mt-4">
          <label className="block mb-2">Datum:</label>
          <input
            type="date"
            className="w-full border p-2"
            value={currentDate}
            onChange={(e) => setCurrentDate(e.target.value)}
          />
          <label className="block mb-2">Bedrag:</label>
          {adjustmentType === "decrease" && (
            <div>
              <select
                className="w-full border p-2"
                value={selectedOption}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setSelectedOption(e.target.value);
                }}
              >
                <option value="6">Bier (€6)</option>
                <option value="4">Frisdrank (€4)</option>
                <option value="10">Mixdrank (€10)</option>
                <option value="custom">Aangepast bedrag</option>
              </select>

              {amount === "custom" && (
                <input
                  type="number"
                  className="w-full border p-2 mt-1"
                  value={customAmount === 0 ? "" : customAmount}
                  onChange={(e) => setCustomAmount(e.target.value)}
                  placeholder="Vul hier het gewenste bedrag in"
                />
              )}
            </div>
          )}
          {adjustmentType === "increase" && (
            <input
              type="number"
              className="w-full border p-2"
              value={amount === 0 ? "" : amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Vul hier het gewenste bedrag in"
            />
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-yellow-500 text-white rounded-md mr-2"
            onClick={handleSubmit}
          >
            Aanpassen
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={onClose}
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdjustCreditsModal;
