import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";

const Darts501 = () => {
  const [player1Score, setPlayer1Score] = useState(501);
  const [player2Score, setPlayer2Score] = useState(501);
  const [currentPlayer, setCurrentPlayer] = useState(1);
  const [winner, setWinner] = useState(null);
  const [inputScore, setInputScore] = useState("");
  const [player1Name, setPlayer1Name] = useState("Speler 1");
  const [player2Name, setPlayer2Name] = useState("Speler 2");
  const [gameStarted, setGameStarted] = useState(false);
  const [initialScore, setInitialScore] = useState(501);
  const [users, setUsers] = useState([]);
  const [player1TotalWins, setPlayer1TotalWins] = useState(0);
  const [player2TotalWins, setPlayer2TotalWins] = useState(0);

  const handleScoreChange = (event) => {
    setInputScore(event.target.value);
  };

  const handleInitialScoreChange = (event) => {
    const selectedInitialScore = parseInt(event.target.value, 10);
    setInitialScore(selectedInitialScore);
    setPlayer1Score(selectedInitialScore);
    setPlayer2Score(selectedInitialScore);
  };

  const fetchDataAndSetGameStarted = () => {
    return fetch(`${API_BASE_URL}/darts501?player1=${encodeURIComponent(player2Name)}&player2=${encodeURIComponent(player1Name)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const totalWinsPlayer1 = calculateTotalWins(data, player1Name);
        const totalWinsPlayer2 = calculateTotalWins(data, player2Name);
        console.log("Total wins:", totalWinsPlayer1, totalWinsPlayer2);
        setPlayer1TotalWins(totalWinsPlayer1);
        setPlayer2TotalWins(totalWinsPlayer2);
      })
      .catch((error) => {
        console.error("Error fetching darts outcomes:", error);
      });
  };
  
  const startGame = () => {
    console.log("Game is started");
  
    fetchDataAndSetGameStarted()
      .then(() => {
        setGameStarted(true);
      });
  };
  
  const calculateTotalWins = (data, playerName) => {
    let totalWins = 0;
  
    data.forEach((outcome) => {
      // Tel hoe vaak de speler in de winnaarslijst voorkomt
      const wins = outcome.winners.filter((winner) => winner === playerName).length;
  
      // Voeg de overwinningen toe aan de totale telling
      totalWins += wins;
    });
  
    return totalWins;
  };
  
  
  

  const resetGame = () => {
    setPlayer1Score(initialScore);
    setPlayer2Score(initialScore);
    setCurrentPlayer(1);
    setWinner(null);
    setInputScore("");
    setGameStarted(false);
  };

  const updateScore = () => {
    if (!gameStarted) {
      alert("Start het spel eerst.");
      return;
    }

    if (winner !== null) {
      // Spel is al afgelopen, negeren
      return;
    }

    const score = parseInt(inputScore, 10);

    if (isNaN(score) || score < 0 || score > 180) {
      alert("Voer een geldige score tussen 1 en 180 in.");
      return;
    }

    let remainingScore = currentPlayer === 1 ? player1Score : player2Score;

    if (score > remainingScore) {
      alert("Ongeldige worp! De score is te hoog.");
      setInputScore("");
      setCurrentPlayer(currentPlayer === 1 ? 2 : 1);
      return;
    }

    if (remainingScore - score === 1) {
      alert("Ongeldige worp! De resterende score zou onder de 2 komen.");
      setInputScore("");
      setCurrentPlayer(currentPlayer === 1 ? 2 : 1);
      return;
    }

    const updatedScore =
      currentPlayer === 1 ? player1Score - score : player2Score - score;

    if (updatedScore === 0) {
      setWinner(currentPlayer);
    }

    if (currentPlayer === 1) {
      setPlayer1Score(updatedScore);
    } else {
      setPlayer2Score(updatedScore);
    }

    setCurrentPlayer(currentPlayer === 1 ? 2 : 1);
    setInputScore("");
  };

  useEffect(() => {
    fetch(`${API_BASE_URL}/users`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    
  }, []);

  useEffect(() => {
    if (winner !== null) {
      const gameData = {
        player1: player1Name,
        player2: player2Name,
        winner: winner === 1 ? player1Name : player2Name,
      };

      fetch(`${API_BASE_URL}/darts501`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gameData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {

          console.log("Game saved successfully:", data);
        })
        .catch((error) => {
          console.error("Error saving game:", error);
        });
        
    }
  }, [winner, player1Name, player2Name]);

  return (
    <div className="bg-black text-yellow-500 border p-8">
      <h2 className="text-2xl font-bold mb-2 md:mb-4">Scorebord</h2>
      {gameStarted && (
  <div>
    <p>Aantal winst {player1Name}: {player1TotalWins}</p>
    <p>Aantal winst {player2Name}: {player2TotalWins}</p>
    <p className="text-lg mb-2 md:mb-4">
      Aan de beurt: {currentPlayer === 1 ? player1Name : player2Name}
    </p>
  </div>
)}



      <table className="w-full mb-2 md:mb-6">
        <thead></thead>
        <tbody>
          <tr>
            <td className="text-lg">
              {gameStarted ? (
                <div>
                  <p>{player1Name}</p>
                  <p>{player1Score}</p>
                </div>
              ) : null}
            </td>
            <td className="text-lg">
              {gameStarted ? (
                <div>
                  <p>{player2Name}</p>
                  <p>{player2Score}</p>
                </div>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>

      {!gameStarted && (
        <div className="mt-2 md:mt-4">
          <label className="text-lg block">
            Beginscore:
            <select
              value={initialScore}
              onChange={handleInitialScoreChange}
              className="ml-2 p-2 border-yellow-500 border rounded bg-black"
            >
              <option value={301}>301</option>
              <option value={501}>501</option>
              <option value={701}>701</option>
              <option value={901}>901</option>
              <option value={1001}>1001</option>
            </select>
          </label>

          <label className="text-lg block mt-2 md:mt-4">
            Naam Speler 1:
            <select
              className="w-full border p-2 bg-black"
              value={player1Name}
              onChange={(e) => setPlayer1Name(e.target.value)}
            >
              <option className="bg-black" value="">
                Selecteer een naam
              </option>
              {users.map((member) => (
                <option
                  className="bg-black"
                  key={member.id}
                  value={member.name}
                >
                  {member.name}
                </option>
              ))}
            </select>
          </label>

          <label className="text-lg block mt-2 md:mt-4">
            Naam Speler 2:
            <select
              className="w-full border p-2 bg-black"
              value={player2Name}
              onChange={(e) => setPlayer2Name(e.target.value)}
            >
              <option className="bg-black" value="">
                Selecteer een naam
              </option>
              {users.map((member) => (
                <option
                  className="bg-black"
                  key={member.id}
                  value={member.name}
                >
                  {member.name}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {!gameStarted && (
        <div className="mt-2 md:mt-4">
          <button
            onClick={startGame}
            className="bg-yellow-500 text-black px-4 py-2 rounded mr-2 md:mr-4"
          >
            Start Spel
          </button>
        </div>
      )}

      {gameStarted && (
        <div className="mt-2 md:mt-4">
          <label className="text-lg">
            Voer de score in:
            <input
              type="number"
              value={inputScore}
              onChange={handleScoreChange}
              className="ml-2 p-2 border-yellow-500 border rounded mb-2 bg-black"
            />
          </label>
          <button
            onClick={updateScore}
            className="bg-yellow-500 text-black px-4 py-2 rounded ml-2 md:ml-4"
          >
            Bijwerken
          </button>
        </div>
      )}

      {gameStarted && (
        <div className="mt-2 md:mt-4">
          <button
            onClick={resetGame}
            className="bg-red-500 text-white px-4 py-2 rounded mt-2 md:mt-4"
          >
            Reset Spel
          </button>
        </div>
      )}

      {winner !== null && (
        <p className="text-lg mt-2 md:mt-4">
          Winnaar: {winner === 1 ? player1Name : player2Name}
        </p>
      )}
    </div>
  );
};

export default Darts501;
