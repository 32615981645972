import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import { useParams } from "react-router-dom";
import beerimage from "../Img/beer_931949.png";

const HistoryTable = () => {
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/users/${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Sorteer de credits op datum in aflopende volgorde
        const sortedCredits = data.credits.sort(
          (a, b) => new Date(b.Date) - new Date(a.Date)
        );

        setData({ ...data, credits: sortedCredits });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [userId]);

  if (loading)
    return (
      <div className="mt-10 text-center font-semibold">
        <div className="text-yellow-500 bg-black inline-block p-4 rounded-lg">
          <div className="animate-bounce text-yellow-500 flex items-center justify-center">
            <a
              href="https://www.pornhub.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={beerimage} alt="bouncing beer" className="h-32" />
            </a>
          </div>
          Laden... de server is aan het opstarten (15-20 seconden). Klik op het
          biertje om de tijd te doden.
        </div>
      </div>
    );

  return (
    <div>
    <h1 className="text-yellow-500 text-left">Tegoed van {data.name}</h1>
    <table className="mt-4 w-full bg-black">
      <thead>
        <tr>
          <th className="w-1/2 px-4 py-2 text-yellow-500 text-left">
            <span className="flex items-center">Datum</span>
          </th>
          <th className="w-1/2 px-4 py-2 text-yellow-500 text-left">Tegoed</th>
        </tr>
      </thead>
      <tbody>
        {data.credits &&
          data.credits.map((credit) => (
            <tr key={credit.Date} className="border-t border-b">
              <td className="w-1/2 px-4 py-2 h-12 text-yellow-500 text-top whitespace-nowrap">
                {new Date(credit.Date).toLocaleDateString("nl-NL")}
              </td>
              <td className="w-1/2 px-4 py-2 h-12 text-yellow-500 text-top">
                <p>€{credit.Amount}</p>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    </div>
  );
};

export default HistoryTable;
