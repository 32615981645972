import React from "react";
import HistoryTable from "../Components/HistoryTable";
const CreditsHistory = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor tegoed geschiedenis
        </h1>
      
      </div>
      <div className="border shadow-sm rounded-lg bg-gray-900 overflow-x-auto"></div>
      <div>
        <HistoryTable />
      </div>
    </div>
  );
};

export default CreditsHistory;
