import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import beerimage from "../Img/beer_931949.png";
import AdjustCreditsModal from "./AdjustCredtisModal";
import NewUserModal from "./NewUserModal";
import AdjustCreditsForMultipleUsersModal from "./AdjustCreditsForMultipleUsersModal";
import { Link } from "react-router-dom";

const CreditsTable = () => {
  const { user, isAuthenticated } = useAuth0();
  const [totalCredits, setTotalCredits] = useState(0);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [selectedUserCredits, setSelectedUserCredits] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [
    showModalAdjustCreditsForMultipleUsers,
    setShowModalAdjustCreditsForMultipleUsers,
  ] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    fetch(`${API_BASE_URL}/users`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let totalCredits = data
          .map((member) => {
            const sortedCredits = member.credits.sort(
              (a, b) => new Date(b.Date) - new Date(a.Date)
            );

            const latestCredit = sortedCredits[0];

            member.latestAmount = latestCredit ? latestCredit.Amount || 0 : 0;

            return member;
          })
          .sort((a, b) => {
            const valueA = a.latestAmount;
            const valueB = b.latestAmount;

            return valueB - valueA;
          });

        setSortedUsers(totalCredits);
        setTotalCredits(totalCredits.reduce((a, b) => a + b.latestAmount, 0));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleSort = (property) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    const sortedData = [...sortedUsers].sort((a, b) => {
      if (property === "name") {
        return order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (property === "credits") {
        return order === "asc"
          ? a.latestAmount - b.latestAmount
          : b.latestAmount - a.latestAmount;
      }
      return 0;
    });

    setSortedUsers(sortedData);
    setSortOrder(order);
  };

  const handleAdjustment = (userId, adjustment, selectedDate, latestAmount) => {
    console.log(latestAmount);
    console.log(selectedUserCredits);
    
    // Gebruik latestAmount als het niet gelijk is aan 0, anders gebruik selectedUserCredits
    const selectedUserCreditsNumber = latestAmount !== undefined ? Number(latestAmount) : Number(selectedUserCredits);
    const adjustmentNumber = Number(adjustment);
    console.log(selectedUserCreditsNumber);
    const newCredits = selectedUserCreditsNumber + adjustmentNumber;
    console.log("New Credits after adjustment:", newCredits);
  
    const date = new Date(selectedDate);
    const currentDate = new Date();
    date.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
  
    const newDate = date.toISOString();
  
    fetch(`${API_BASE_URL}/users/adjustcredits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: userId,
        credits: newCredits,
        date: newDate,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        // Update de gebruikerslijst
        fetch(`${API_BASE_URL}/users`)
          .then((response) => response.json())
          .then((data) => {
            let sortedData = data
              .map((member) => {
                const sortedCredits = member.credits.sort(
                  (a, b) => new Date(b.Date) - new Date(a.Date)
                );
                const latestCredit = sortedCredits[0];
                member.latestAmount = latestCredit ? latestCredit.Amount || 0 : 0;
                return member;
              })
              .sort((a, b) => b.latestAmount - a.latestAmount);
  
            setSortedUsers(sortedData);
            setTotalCredits(sortedData.reduce((a, b) => a + b.latestAmount, 0));
          });
      })
      .catch((error) => {
        console.error("Error adjusting credits:", error);
      });
  };
  

  const handleAdjustButtonClick = (userId, userName, amount) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setSelectedUserCredits(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUserId(null);
    setShowModal(false);
  };

  const handleNewUserButtonClick = (newUser) => {
    setShowModalNewUser(true);
    setNewUser(newUser);
  };

  const handleCloseModalNewUser = () => {
    setNewUser(null);
    setShowModalNewUser(false);
  };

  const handleAdjustCreditsForMultipleUsersOpen = () => {
    setShowModalAdjustCreditsForMultipleUsers(true);
  };

  const handleAdjustCreditsForMultipleUsersClose = () => {
    setShowModalAdjustCreditsForMultipleUsers(false);
  };

  const handleAdjustmentNewUser = (newUser) => {
    fetch(`${API_BASE_URL}/users/newUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: newUser,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        fetch(`${API_BASE_URL}/users`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            let totalCredits = data
              .map((member) => {
                const latestCredit = member.credits[0];
                return latestCredit ? latestCredit.Amount || 0 : 0;
              })
              .reduce((a, b) => a + b, 0);

            let sortedData = data
              .map((member) => {
                const sortedCredits = member.credits.sort(
                  (a, b) => new Date(b.Date) - new Date(a.Date)
                );

                const latestCredit = sortedCredits[0];

                member.latestAmount = latestCredit
                  ? latestCredit.Amount || 0
                  : 0;

                return member;
              })
              .sort((a, b) => b.latestAmount - a.latestAmount);

            setSortedUsers(sortedData);
            setTotalCredits(totalCredits);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adjusting credits:", error);
      });
  };

  if (loading)
    return (
      <div className="mt-10 text-center font-semibold">
        <div className="text-yellow-500 bg-black inline-block p-4 rounded-lg">
          <div className="animate-bounce text-yellow-500 flex items-center justify-center">
            <a
              href="https://www.pornhub.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <img src={beerimage} alt="bouncing beer" className="h-32" />
            </a>
          </div>
          Laden... de server is aan het opstarten (15-20 seconden). Klik op het
          biertje om de tijd te doden.
        </div>
      </div>
    );
  return (
    <div>
      {isAuthenticated && (
        <p className="font-semibold text-lg md:text-2xl text-yellow-500">
          Mogguh {user.name}{" "}
        </p>
      )}
      {isAuthenticated && user && user.name === "admin@admin.com" ? (
        <>
          <div className="flex space-x-4">
            <button
              className="font-semibold text-lg md:text-2xl text-yellow-500 bg-black border border-yellow-500 px-4 py-2 rounded-md transition duration-200 hover:bg-yellow-500 hover:text-black"
              onClick={() => handleNewUserButtonClick()}
            >
              Nieuw lid
            </button>

            <button
              className="font-semibold text-lg md:text-2xl text-yellow-500 bg-black border border-yellow-500 px-4 py-2 rounded-md transition duration-200 hover:bg-yellow-500 hover:text-black"
              onClick={() => handleAdjustCreditsForMultipleUsersOpen()}
            >
              Avond aanmaken
            </button>
          </div>
        </>
      ) : (
        <p></p>
      )}

      <table className="mt-4 w-full bg-black">
        <thead>
          <tr>
            <th
              className="w-1/3 px-4 py-2  text-yellow-500 text-left "
              onClick={() => handleSort("name")}
            >
              <span className="flex items-center">
                Naam
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 inline-block ml-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                  />
                </svg>
              </span>
            </th>
            <th
              className="w-1/3 px-4 py-2  text-yellow-500 text-left"
              onClick={() => handleSort("credits")}
            >
              Tegoed
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 inline-block ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((member) => {
            return (
              <tr key={member.id} className="border-t border-b">
                <td
                  className="w-1/3 px-4 py-2 h-12 text-yellow-500 text-top whitespace-nowrap cursor-pointer"
                  onClick={() => handleSort("name")}
                >
                  <Link
                    to={`/CreditsHistory/${member.id}`}
                    className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                  >
                    {member.name}
                  </Link>
                </td>
                <td
                  className="w-1/3 px-4 py-2 h-12 text-yellow-500 text-top cursor-pointer"
                  onClick={() => handleSort("credits")}
                >
                  <Link
                    to={`/CreditsHistory/${member.id}`}
                    className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                  >
                    <p>€{member.latestAmount}</p>
                  </Link>
                </td>
                <td className="w-1/12 px-4 py-2 text-top">
                  {isAuthenticated &&
                  user &&
                  user.name === "admin@admin.com" ? (
                    <>
                      <button
                        className="text-yellow-500 border-yellow-500"
                        size="sm"
                        variant="outline"
                        onClick={() =>
                          handleAdjustButtonClick(
                            member.id,
                            member.name,
                            member.latestAmount
                          )
                        }
                      >
                        Pas tegoed aan
                      </button>
                    </>
                  ) : (
                    <p></p>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p className="font-semibold text-lg md:text-2xl text-yellow-500">
        Totaal tegoed: €{totalCredits}
      </p>
      {showModal && (
        <AdjustCreditsModal
          onClose={handleCloseModal}
          onAdjust={handleAdjustment}
          userId={selectedUserId}
          userName={selectedUserName}
        />
      )}
      {showModalNewUser && (
        <NewUserModal
          onClose={handleCloseModalNewUser}
          onAdjust={handleAdjustmentNewUser}
          userName={newUser}
        />
      )}

      {showModalAdjustCreditsForMultipleUsers && (
        <AdjustCreditsForMultipleUsersModal
          onClose={handleAdjustCreditsForMultipleUsersClose}
          onAdjust={handleAdjustment}
          users={sortedUsers}
        />
      )}
    </div>
  );
};

export default CreditsTable;
