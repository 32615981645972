import Photo from "../Components/Photo";
const PhotoGallery = () => {
  return (
    <div>
      <div>
        <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor foto overzicht
        </h1>
      </div>
      <div>
        <Photo />
      </div>
    </div>
  );
};

export default PhotoGallery;
