import React, { useState, useEffect, useRef } from "react";
import KantoorLogo from "../Img/Logo_Het Kantoor_variant_3.png";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { isAuthenticated } = useAuth0();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="flex h-14 items-center gap-4 border-b px-6 bg-gray-800/40">
      <Link to="/">
        <img src={KantoorLogo} alt="logo" className="h-16" />
      </Link>

      <div className="relative ml-auto" ref={dropdownRef}>
        <button onClick={toggleDropdown} className="text-white focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 text-yellow-500"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
        {isDropdownOpen && (
          <div className="absolute bg-gray-800 text-white text-base mt-2 w-32 rounded-md shadow-lg right-0">
            <div className="py-2">
              {isAuthenticated ? (
                <>
                  <button
                    onClick={closeDropdown} 
                    className="block px-4 py-2 text-sm leading-5 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    <LogoutButton />
                  </button>
                  <Link
                    to="/"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Overzicht
                  </Link>
                  <Link
                    to="/Darts"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Darts
                  </Link>
                  <Link
                    to="/MoneyTransfer"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Betaal link
                  </Link>
                  <Link
                    to="/Agenda"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Agenda
                  </Link>
                  <Link
                    to="/Photo"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Foto's
                  </Link>
                </>
              ) : (
                <>
                  <button
                    onClick={closeDropdown} 
                    className="block px-4 py-2 text-sm leading-5 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    <LoginButton />
                  </button>
                  <Link
                    to="/"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Overzicht
                  </Link>
                  <Link
                    to="/Darts"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Darts
                  </Link>
                  <Link
                    to="/MoneyTransfer"
                    onClick={closeDropdown}
                    className="block px-4 py-2 text-sm leading-5 text-yellow-500 border-yellow-500 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  >
                    Betaal link
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
