import React, { useState, useEffect } from 'react';
import beerimage from "../Img/beer_931949.png";
const PhotoUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Selecteer eerst een bestand!');
      return;
    }

    const formData = new FormData();
    formData.append('photo', selectedFile);
    formData.append('description', description);

    try {
      setLoading(true);

      const response = await fetch('https://het-kantoor.onrender.com/uploadPhoto', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Bestand succesvol geüpload!');
        fetchPhotos();
      } else {
        alert('Er is een fout opgetreden tijdens het uploaden van het bestand.');
      }
    } catch (error) {
      console.error('Fout tijdens het uploaden van het bestand:', error);
    } finally {
      setLoading(false);
      setSelectedFile(null);
      setDescription('');
    }
  };

  const fetchPhotos = async () => {
    try {
      const response = await fetch('https://het-kantoor.onrender.com/getPhotos');
      if (response.ok) {
        const data = await response.json();
        setPhotos(data.photos);
        setLoading(false)
      } else {
        console.error('Fout bij het ophalen van fotos');
      }
    } catch (error) {
      console.error('Fout bij het ophalen van fotos:', error);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  if (loading) {
    return (
      <div className="mt-10 text-center font-semibold">
        <div className="text-yellow-500 bg-black inline-block p-4 rounded-lg">
          <div className="animate-bounce text-yellow-500 flex items-center justify-center">
            <a
              href="https://www.pornhub.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={beerimage} alt="bouncing beer" className="h-32" />
            </a>
          </div>
          Laden...Foto's laden duurt lang (Veel data) Klik op het
          biertje om de tijd te doden.
        </div>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto mt-8 p-4 bg-black rounded-md text-yellow-500">
      <input className="w-full" type="file" onChange={handleFileChange} />
      <label htmlFor="description">Beschrijving (bv datum + locatie): </label>
      <input
        type="text"
        id="description"
        value={description}
        onChange={handleDescriptionChange}
      />
      <br />
      <button
        className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded-md"
        onClick={handleUpload}
        disabled={loading}
      >
        Upload Foto
      </button>
      <div className="mt-8">
        {photos.map((photo) => (
          <div key={photo.photo_id} className="flex flex-col mt-5 mb-5 items-center text-white border border-yellow-500">
            <img
              src={`data:image/jpeg;base64,${photo.photo_data}`}
              alt="Uploaded"
              className="max-w-full max-h-300px mt-4"
            />
            <p className="text-yellow-500">{photo.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoUploader;
