import React, { useState, useEffect } from "react";
import beerimage from "../Img/beer_931949.png";
import { API_BASE_URL } from "../config";
//import { API_BASE_URL } from "..config";

const DartsOverallWins = () => {
  const [loading, setLoading] = useState(true);
  const [darts501Winners, setDarts501Winners] = useState({});
  const [dartsHitEveryNumberWinners, setDartsHitEveryNumberWinners] = useState({});
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    // Functie om de winnaars van Darts 501 op te halen
    const fetchDarts501Winners = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/getDarts501Winners`);
        if (!response.ok) {
          throw new Error("Failed to fetch Darts 501 winners");
        }
        const data = await response.json();
        setDarts501Winners(data);
      } catch (error) {
        console.error(error);
      }
    };

    // Functie om de winnaars van Darts Hit Every Number op te halen
    const fetchDartsHitEveryNumberWinners = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/getDartsHitEveryNumberWinners`);
        if (!response.ok) {
          throw new Error("Failed to fetch Darts Hit Every Number winners");
        }
        const data = await response.json();
        setDartsHitEveryNumberWinners(data);
      } catch (error) {
        console.error(error);
      }
    };

    // Voer de functies uit om de winnaars op te halen wanneer het component gemonteerd is
    fetchDarts501Winners();
    fetchDartsHitEveryNumberWinners();
    
    // Zet loading op false wanneer het laden is voltooid
    setLoading(false);
  }, []);

  // Functie om de tabel te sorteren op basis van de geselecteerde kolom en richting
  const sortPlayers = (players) => {
    return players.sort((a, b) => {
      const winsA = sortColumn === "501" ? (darts501Winners[a] || 0) : (dartsHitEveryNumberWinners[a] || 0);
      const winsB = sortColumn === "501" ? (darts501Winners[b] || 0) : (dartsHitEveryNumberWinners[b] || 0);
      if (sortDirection === "desc") {
        return winsB - winsA;
      } else {
        return winsA - winsB;
      }
    });
  };

  // Functie om de tabel te sorteren op basis van de geselecteerde kolom
  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      // Wijzig de sorteerrichting als dezelfde kolom opnieuw wordt geklikt
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Stel de nieuwe kolom in voor sorteren
      setSortColumn(columnName);
      setSortDirection("desc");
    }
  };

  // Lijst van alle unieke spelers
  const allPlayers = Array.from(new Set([...Object.keys(darts501Winners), ...Object.keys(dartsHitEveryNumberWinners)]));

  if (loading)
    return (
      <div className="mt-10 text-center font-semibold">
        <div className="text-yellow-500 bg-black inline-block p-4 rounded-lg">
          <div className="animate-bounce text-yellow-500 flex items-center justify-center">
            <a
              href="https://www.pornhub.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={beerimage} alt="bouncing beer" className="h-32" />
            </a>
          </div>
          Laden... de server is aan het opstarten (15-20 seconden). Klik op het
          biertje om de tijd te doden.
        </div>
      </div>
    );

  return (
    <div>
      <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor Darts winnaars
        </h1>
      <table className="bg-black text-yellow-500 ">
        <thead>
          <tr>
            <th className="py-2 px-4 cursor-pointer border border-yellow-500" onClick={() => handleSort("name")}>Naam</th>
            <th className="py-2 px-4 cursor-pointer border border-yellow-500" onClick={() => handleSort("501")}>501</th>
            <th className="py-2 px-4 cursor-pointer border border-yellow-500" onClick={() => handleSort("everyNumber")}>Ieder getal</th>
            <th className="py-2 px-4 cursor-pointer border border-yellow-500" onClick={() => handleSort("total")}>Totaal</th>
          </tr>
        </thead>
        <tbody>
          {sortPlayers(allPlayers).map(player => (
            <tr key={player}>
              <td className="py-2 px-4 text-center border border-yellow-500">{player}</td>
              <td className="py-2 px-4 text-center border border-yellow-500">{darts501Winners[player] || 0}</td>
              <td className="py-2 px-4 text-center border border-yellow-500">{dartsHitEveryNumberWinners[player] || 0}</td>
              <td className="py-2 px-4 text-center border border-yellow-500">{(darts501Winners[player] || 0) + (dartsHitEveryNumberWinners[player] || 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DartsOverallWins;
