import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
  return (
    <button
      onClick={() => loginWithRedirect()}
      className="text-yellow-500 border-yellow-500"
      variant="outline"
    >
      Login
    </button>
  );
};
export default LoginButton;
