import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import NavBar from './Components/Navbar';
import Homepage from './Pages/Homepage';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import CreditsHistoryPage from './Pages/CreditsHistoryPage';
import AgendaPage from './Pages/AgendaPage';
import DartsPage from './Pages/DartsPage';
import Footer from './Components/Footer';
import MoneyTransferPage from './Pages/MoneyTransferPage';
import PhotoGalleryPage from './Pages/PhotoGalleryPage.js';
import DartsOverallWins from './Components/DartsOverallWins.js';


const AppRoutes = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      {isAuthenticated ? (
        <>
        
          <Route path="/CreditsHistory/:userId" element={<CreditsHistoryPage />} />
          <Route path="/Agenda" element={<AgendaPage />} />
          <Route path="/Photo" element={<PhotoGalleryPage />} />
          <Route path="/MoneyTransfer" element={<MoneyTransferPage />} />
          <Route path="/Darts" element={<DartsPage />} />
          <Route path="/DartsOverallWins" element={<DartsOverallWins />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      ) : (
        <>
          <Route path="/CreditsHistory/:userId" element={<CreditsHistoryPage />} />
          <Route path="/MoneyTransfer" element={<MoneyTransferPage />} />
          <Route path="/Darts" element={<DartsPage />} />
          <Route path="/DartsOverallWins" element={<DartsOverallWins />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}
    </Routes>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="dev-ie13exvycq67h1fb.us.auth0.com"
        clientId="7MYsUZpBjSydurcEjRVMMpPGHAdoOeoO"
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <div className="flex flex-col h-screen bg-gradient-to-r from-purple-800 via-pink-900 to-yellow-800">
          <NavBar />
          <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6 overflow-auto">
            <AppRoutes />
          <Footer />
          </main>
        </div>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
