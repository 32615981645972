import React, { useState } from "react";

const AdjustCreditsModal = ({ onClose, onAdjust, userName }) => {
  const [newUser, setNewUser] = useState(null);

  const handleSubmit = () => {
    userName = newUser;
    onAdjust(userName);
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md max-w-xs w-full">
        <h2 className="text-lg font-semibold mb-4">Nieuw lid aanmaken</h2>
        <label className="block mb-2">Naam:</label>
        <input
          type="text"
          className="w-full border p-2"
          onChange={(e) => setNewUser(e.target.value)}
        ></input>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-yellow-500 text-white rounded-md mr-2"
            onClick={handleSubmit}
          >
            Aanmaken
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={onClose}
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdjustCreditsModal;
