import React, { useState } from "react";
import Darts501 from "../Components/Darts501";
import DartsHitEveryone from "../Components/DartsHitEveryNumber.js";
import { Link } from "react-router-dom";

const DartsPage = () => {
  const [selectedMode, setSelectedMode] = useState("501");

  const handleModeChange = (event) => {
    setSelectedMode(event.target.value);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor Darts
        </h1>
        
        

        <div className="flex items-center">
          <label className="text-white mr-2">Selecteer gamemode:</label>
          <select
            value={selectedMode}
            onChange={handleModeChange}
            className="bg-gray-800 text-white p-2 rounded-md"
          >
            <option value="501">501</option>
            <option value="hitEveryNumber">Elk cijfer raken</option>
          </select>
        </div>
      </div>
      <Link
  to={"/DartsOverallWins"}
  className="block max-w-xs mx-auto text-center bg-black text-yellow-500 font-semibold text-lg md:text-2xl border border-yellow-500 px-4 py-2 rounded-md transition duration-200 hover:bg-yellow-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-yellow-400 mb-1"
>
  <h1>Winst tabel</h1>
</Link>


      <div className=" bg-gray-900 overflow-x-auto"></div>
      <div>
        {selectedMode === "501" ? <Darts501 /> : <DartsHitEveryone />}
        
      </div>
      
    </div>
  );
};

export default DartsPage;
