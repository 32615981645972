import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";

const DartsHitEveryone = () => {
  const [player1Name, setPlayer1Name] = useState("Speler 1");
  const [player2Name, setPlayer2Name] = useState("Speler 2");
  const [gameStarted, setGameStarted] = useState(false);
  const [users, setUsers] = useState([]);
  const [player1TotalWins, setPlayer1TotalWins] = useState(0);
  const [player2TotalWins, setPlayer2TotalWins] = useState(0);
  const [throwsPerNumber, setThrowsPerNumber] = useState(1);
  const [selectedNumbersPlayer1, setSelectedNumbersPlayer1] = useState([]);
  const [selectedNumbersPlayer2, setSelectedNumbersPlayer2] = useState([]);
  const [remainingNumbersPlayer1, setRemainingNumbersPlayer1] = useState("alles");
  const [remainingNumbersPlayer2, setRemainingNumbersPlayer2] = useState("alles");
  
  const fetchDataAndSetGameStarted = () => {
    return fetch(
      `${API_BASE_URL}/dartsHitEveryNumber?player1=${encodeURIComponent(
        player2Name
      )}&player2=${encodeURIComponent(player1Name)}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const totalWinsPlayer1 = calculateTotalWins(data, player1Name);
        const totalWinsPlayer2 = calculateTotalWins(data, player2Name);
        console.log("Total wins:", totalWinsPlayer1, totalWinsPlayer2);
        setPlayer1TotalWins(totalWinsPlayer1);
        setPlayer2TotalWins(totalWinsPlayer2);
      })
      .catch((error) => {
        console.error("Error fetching darts outcomes:", error);
      });
  };

  const startGame = () => {
    fetchDataAndSetGameStarted().then(() => {
      setGameStarted(true);
    });
  };

  const calculateTotalWins = (data, playerName) => {
    let totalWins = 0;

    data.forEach((outcome) => {
      // Tel hoe vaak de speler in de winnaarslijst voorkomt
      const wins = outcome.winners.filter(
        (winner) => winner === playerName
      ).length;
      console.log("Wins:", wins);
      // Voeg de overwinningen toe aan de totale telling
      totalWins += wins;
    });

    return totalWins;
  };

  const resetGame = () => {
    setGameStarted(false);
    setSelectedNumbersPlayer1([]);
    setSelectedNumbersPlayer2([]);
  };

  const generateNumberButtons = (playerName, selectedNumbers) => {
    const buttons = [];
    const maxButtonsPerRow = 3;

    let currentRowButtons = [];

    // Voeg knoppen toe voor de nummers 1 t/m 20 en de bull
    for (let i = 0; i <= 20; i++) {
      const number = i === 20 ? "bull" : i + 1;

      for (let j = 0; j < throwsPerNumber; j++) {
        const buttonId = `${playerName}-${number}-${j}`;

        const isNumberSelected = selectedNumbers.some(
          (item) => item.number === number && item.id === buttonId
        );

        currentRowButtons.push(
          <td key={buttonId} className="px-2">
            <button
  id={buttonId}
  className={`w-full py-2 rounded cursor-pointer ${
    isNumberSelected ? "bg-green-500" : "bg-yellow-500"
  } text-black`}
  onClick={() => handleNumberClick(playerName, number, buttonId)}
>
  {number === "bull" ? "Bull" : number}
</button>

          </td>
        );
      }

      if (currentRowButtons.length === maxButtonsPerRow) {
        buttons.push(
          <tr key={i} className="mb-2">
            {currentRowButtons}
          </tr>
        );
        currentRowButtons = [];
      }
    }

    // Voeg de resterende knoppen toe, indien nodig
    if (currentRowButtons.length > 0) {
      buttons.push(
        <tr key="lastRow" className="mb-2">
          {currentRowButtons}
        </tr>
      );
    }

    return (
      <div className="mt-4">
        <table className="w-full table-auto">
          <tbody>{buttons}</tbody>
        </table>
      </div>
    );
  };

  const handleNumberClick = (playerName, number, buttonId) => {
    const selectedNumbers =
      playerName === player1Name
        ? selectedNumbersPlayer1
        : selectedNumbersPlayer2;
  
    if (selectedNumbers.length < 21 * throwsPerNumber) {
      const isNumberSelected = selectedNumbers.some(
        (item) => item.number === number && item.id === buttonId
      );
  
      const updatedSelectedNumbers = isNumberSelected
        ? selectedNumbers.filter((item) => item.id !== buttonId)
        : [
            ...selectedNumbers,
            { playerName, number, id: buttonId, color: "green" },
          ];
  
      if (playerName === player1Name) {
        setSelectedNumbersPlayer1(updatedSelectedNumbers);
        const remainingNumbersCount = 21 * throwsPerNumber - updatedSelectedNumbers.length 
        setRemainingNumbersPlayer1(remainingNumbersCount)
      } else {
        setSelectedNumbersPlayer2(updatedSelectedNumbers);
        const remainingNumbersCount = 21 * throwsPerNumber - updatedSelectedNumbers.length
        setRemainingNumbersPlayer2(remainingNumbersCount)
      }
    }
  };
  
  

  useEffect(() => {
    fetch(`${API_BASE_URL}/users`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    const allButtonsSelectedPlayer1 =
      selectedNumbersPlayer1.length === 21 * throwsPerNumber;

    // Controleren of alle knoppen van speler 2 zijn geselecteerd
    const allButtonsSelectedPlayer2 =
      selectedNumbersPlayer2.length === 21 * throwsPerNumber;

    // Console-log als alle knoppen van speler 1 zijn geselecteerd
    if (allButtonsSelectedPlayer1) {
      alert(`Winnaar: ${player1Name}`);
      fetch(`${API_BASE_URL}/dartsHitEveryNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          player1: player1Name,
          player2: player2Name,
          winner: player1Name,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Game saved successfully:", data);
        })
        .catch((error) => {
          console.error("Error saving game:", error);
        });
    }

    // Console-log als alle knoppen van speler 2 zijn geselecteerd
    if (allButtonsSelectedPlayer2) {
      alert(`Winnaar: ${player2Name}`);
      fetch(`${API_BASE_URL}/dartsHitEveryNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          player1: player1Name,
          player2: player2Name,
          winner: player2Name,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Game saved successfully:", data);
        })
        .catch((error) => {
          console.error("Error saving game:", error);
        });
    }
  }, [
    selectedNumbersPlayer1,
    selectedNumbersPlayer2,
    player1Name,
    player2Name,
    throwsPerNumber,
  ]);

  return (
    <div className="bg-black text-yellow-500 border p-4 md:p-8">
      <h2 className="text-2xl font-bold mb-2 md:mb-4">Scorebord</h2>
      {gameStarted && (
        <div>
          <p>
            Aantal winst {player1Name}: {player1TotalWins}
          </p>
          <p>
            Aantal winst {player2Name}: {player2TotalWins}
          </p>
        </div>
      )}
      {gameStarted && (
        <div>
          <div className="mt-4">
            <p>{`Cijferlijst voor ${player1Name}: (Nog ${remainingNumbersPlayer1} te gaan)`}</p>
            <div>
              {generateNumberButtons(
                player1Name,
                selectedNumbersPlayer1,
                throwsPerNumber
              )}
            </div>
          </div>
          <div className="mt-4">
            <p>{`Cijferlijst voor ${player2Name}: (Nog ${remainingNumbersPlayer2} te gaan)`}</p>
            <div>
              {generateNumberButtons(
                player2Name,
                selectedNumbersPlayer2,
                throwsPerNumber
              )}
            </div>
          </div>
        </div>
      )}
      {!gameStarted && (
        <div className="mt-2 md:mt-4">
          <label className="text-lg block">
            Naam Speler 1:
            <select
              className="w-full border p-2 bg-black"
              value={player1Name}
              onChange={(e) => setPlayer1Name(e.target.value)}
            >
              <option className="bg-black" value="">
                Selecteer een naam
              </option>
              {users.map((member) => (
                <option
                  className="bg-black"
                  key={member.id}
                  value={member.name}
                >
                  {member.name}
                </option>
              ))}
            </select>
          </label>
          <label className="text-lg block mt-2 md:mt-4">
            Naam Speler 2:
            <select
              className="w-full border p-2 bg-black"
              value={player2Name}
              onChange={(e) => setPlayer2Name(e.target.value)}
            >
              <option className="bg-black" value="">
                Selecteer een naam
              </option>
              {users.map((member) => (
                <option
                  className="bg-black"
                  key={member.id}
                  value={member.name}
                >
                  {member.name}
                </option>
              ))}
            </select>
          </label>
          <label className="text-lg block mt-2 md:mt-4">
            Aantal worpen per nummer:
            <select
              className="w-full border p-2 bg-black"
              value={throwsPerNumber}
              onChange={(e) => setThrowsPerNumber(Number(e.target.value))}
            >
              <option className="bg-black" value={1}>
                1 keer
              </option>
              <option className="bg-black" value={3}>
                3 keer
              </option>
            </select>
          </label>
        </div>
      )}

      {!gameStarted && (
        <div className="mt-2 md:mt-4">
          <button
            onClick={startGame}
            className="bg-yellow-500 text-black px-4 py-2 rounded mr-2 md:mr-4"
          >
            Start Spel
          </button>
        </div>
      )}

      {gameStarted && (
        <div className="mt-2 md:mt-4">
          <button
            onClick={resetGame}
            className="bg-red-500 text-white px-4 py-2 rounded mt-2 md:mt-4"
          >
            Reset Spel
          </button>
        </div>
      )}
    </div>
  );
};

export default DartsHitEveryone;
