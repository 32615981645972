import BetalenOuwe from "../Img/BetalenOuwe.png";

const MoneyTransferPage = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-lg text-yellow-500">
          Het Kantoor betaalverzoek
        </h1>
      </div>
      <div className="border shadow-sm rounded-lg bg-gray-900 overflow-x-auto"></div>
      <div className="flex justify-center">
        <a
              href="https://www.ing.nl/payreq/m/?trxid=NabY2ylmpiXcEg5sjfnCaYiVJ05G9xnz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={BetalenOuwe}
            alt="BetalenOuwe"
            className="h-72"
          />
        </a>
      </div>
      <div></div>
      <p className="font-semibold text-xs text-center mt-10 text-yellow-500">
        Klik op op de sticker om naar het betaalverzoek te gaan. Stuur een screenshot/appje naar Tom of Jelle met het bedrag zodat je tegoed aangepast kan worden!
      </p>
      
    </div>
  );
};

export default MoneyTransferPage;
