import CreditsTable from "../Components/CreditsTable";
const Homepage = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-lg md:text-2xl text-yellow-500">
          Het Kantoor tegoed overzicht
        </h1>
      
      </div>
      <div className="border shadow-sm rounded-lg bg-gray-900 overflow-x-auto"></div>
      <div>
        <CreditsTable />
      </div>
    </div>
  );
};

export default Homepage;
